html {
  background: rgb(0, 0, 0);
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 77%,
      rgba(25, 22, 46, 1) 100%
    )
    fixed;
}

body {
  color: white;
}

button {
  appearance: none;
  background: #333;
  border: 1px solid white;
  border-radius: 3px;
  color: white;
  padding: 0.25em;
  cursor: pointer;
}

button:disabled {
  opacity: 50%;
}

input {
  appearance: none;
  background: none;
  border: 1px solid white;
  color: inherit;
  border-radius: 3px;
  padding: 0.5em;
}

input:focus {
  background: rgba(255, 255, 255, 0.1);
}

input::placeholder {
  color: rgba(255, 255, 255, 0.75);
}
