.container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.count {
  display: inline-block;
}

.inputLabel {
  text-align: center;
  margin-bottom: 1rem;
}

.valueInput {
  text-align: center;
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 1rem;
}
