.container {
  background: rgba(255, 255, 255, 0.1);
  display: inline-block;
  width: 10rem;
  padding: 1rem 0;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: center;
}

.name {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.counterSection {
  text-align: center;
  margin-top: 0.5rem;
  width: 100%;
  padding: 0 1rem;
}

.productionSection {
  padding: 0 1rem;
  margin-top: 1rem;
}
