.container {
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.resources {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: center;
  max-width: 36rem;
}

.infoPanel {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  margin-top: 1rem;
  align-items: center;
}

.toolbar {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  font-size: 0.75rem;
}
